import React from "react";
import "./FolderDetails.css";
import GuideCard from "../guide-card/GuideCard";

interface Props {
    data: any;
};

const FolderDetails = ({ data }: Props) => {
    const guideCards = data?.tours?.map((guideCardItem: any) => {
        let dynamicImagePath = `${process.env.PUBLIC_URL}/assets/default.png`;
        if(guideCardItem.image_url) {
            const currentImageName = guideCardItem.image_url.split('/').at(-1);
            dynamicImagePath = `${process.env.PUBLIC_URL}/assets/package/image/${currentImageName}`;
        }
        let dynamicVideoPath = "";
        if(guideCardItem.video_url) {
            const currentVideoName = guideCardItem.video_url.split('/').at(-1);
            dynamicVideoPath = `${process.env.PUBLIC_URL}/assets/package/video/${currentVideoName}`;
        }
        return <GuideCard key={guideCardItem.tour_id} imageUrl={dynamicImagePath} title={guideCardItem.tour_title} description={guideCardItem.tour_description} videoUrl={dynamicVideoPath} tourId={guideCardItem.tour_id} />
    })
    return (
        <>
            <p className="folder-details-heading">{data?.category_title ?? "Search Results:"}</p>
            {guideCards?.length > 0 && guideCards}
            {guideCards?.length === 0 && <div className="empty-guide-search-results">Sorry, no guide templates found for your search. Try a different search term or explore the available templates.</div>}
        </>
    );
}

export default FolderDetails;